import React from 'react';
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar';

import '../styles/template.css';
import '../styles/download.css';
// import '../styles/about.css'
// import '../styles/team.css'

import sportsBackground from '../images/sports-background.jpeg';
import appIcon from '../images/app-icon-nov22.png';
import appStore from '../images/app-store.svg';
import googlePlay from '../images/google-play.svg';
import Layout from '../components/Layout';

// markup
const Download = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Download - iOS & Android</title>
      </Helmet>
      <main id="download">
        <NavBar location={location} />
        <section id="hero">
          <img id="hero-img" src={sportsBackground} />
        </section>
        <section className="content-section">
          <div
            className="page-content"
            style={{
              marginTop: -80,
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <img
              style={{
                width: 100,
                height: 100,
                borderRadius: 20,
                marginBottom: 10,
              }}
              src={appIcon}
            />
            <h2 className="text-center">Racqy: Discover new adventures</h2>
            <div
              className="links"
              style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}
            >
              <a href="https://apps.apple.com/us/app/racqy-find-activities/id1609593141?ppid=51b84a43-2620-41d6-8b02-8ce6e24906d4">
                <img
                  style={{
                    width: 200,
                    height: 50,
                    borderRadius: 20,
                    marginBottom: 10,
                    cursor: 'pointer',
                  }}
                  src={appStore}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.racqy.racqy">
                <img
                  style={{
                    width: 200,
                    height: 50,
                    borderRadius: 20,
                    marginBottom: 10,
                    cursor: 'pointer',
                  }}
                  src={googlePlay}
                />
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Download;
